import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

interface APIResponse {
    success: number,
    message: string,
    status: number,
    data : any
}

@Injectable()
export class RestApiService {

    apiUrl = environment.apiUrl;

    constructor(
        private http: HttpClient,
        public auth: AuthenticationService) {
    }

    doAuthPost(url:string, data:any): Observable<APIResponse> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.auth.getToken()
            })
        }
        return this.http.post<APIResponse>(url, data, httpOptions)
        .map((response: APIResponse) => {
            if (response.status == 401) {
                this.auth.logout()
            }
            return response
        })
    }

    dataTable(path:string, dataTablesParameters:any) : Observable<APIResponse> {
        let url = `${this.apiUrl}${path}`;
        return this.doAuthPost(url, dataTablesParameters)
    }
    
}