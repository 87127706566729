import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class AuthenticationService implements CanActivate {
    userData: any;
    token: any;
    error = '';
    apiToken: any;
    userId: any;
    userType: string;
    apiUrl = environment.apiUrl;
    constructor(private http: HttpClient,
        public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let user_roles_capabilities = localStorage.getItem('role_capabilities');
        let user_role = localStorage.getItem('role');

        if (user_role == "CUSTOM_ROLE") {
            if (user_roles_capabilities.indexOf(route.data.path_name) == -1) {
                this.router.navigate(['/pages']);
            }
        }
        return true;
    }

    isUserLoggedIn(): boolean {
        if (localStorage.getItem('token') == null && this.token == null) {
            this.router.navigate(['/login']);
            return false;
        } else {
            return true;
        }
    }

    login(email: string, password: string): Observable<boolean> {
        let url = `${this.apiUrl}login`;
        const body = {
            "email": email,
            "password": password
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }

        return this.http.post(url, body, httpOptions)
            .map((response: Response) => {
                let res: any = response;
                if (res.success == 1 && res.data.role_capabilities != null) {
                    const token = res.data.token;
                    this.userType = res.data.role;

                    if (this.isLocalStorageNameSupported() == true) {
                        localStorage.setItem('token', token);
                        localStorage.setItem('role', this.userType);
                        localStorage.setItem('role_capabilities', res.data.role_capabilities);
                    } else {
                        this.token = token;
                    }
                    return true;
                } else {
                    this.error = res.message
                    return false;
                }
            })
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('role_capabilities');
        this.token = null;
        this.userType = null;
        this.router.navigate(['']);
    }

    getToken() {
        if (this.isLocalStorageNameSupported() == true) {
            return localStorage.getItem('token');
        } else {
            return this.token;
        }
    }

    getUserId() {
        const helper = new JwtHelperService();
        const decoded = helper.decodeToken(this.getToken());
        return decoded
    }

    isLocalStorageNameSupported() {
        const testKey = 'test', storage = window.localStorage;
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } catch (error) {
            return false;
        }
    }

    getSmsLinkData(alertId: string, lang: string, target: string) {
        let url = `${this.apiUrl}get-sms-link-body/${alertId}?target=${target}&lang=${lang}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }

        return this.http.post(url, httpOptions)
            .map((response: Response) => {
                let res: any = response;

                if (res.success == 1) {
                    return res;
                }
            })
    }

}
