import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { auth2StrategyOptions } from '@nebular/auth';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    userFilter = '';
    apiUrl = environment.apiUrl;
    redirectUrl: string = '';
    constructor(private route: ActivatedRoute,
        private http: HttpClient,
        public router: Router,
        public auth: AuthenticationService) {
    }

    savePostData(formData, postUrl) {
        let url = `${this.apiUrl}` + `${postUrl}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.auth.getToken()
            })
        }
        return this.http.post(url, formData, httpOptions)
            .map((response: Response) => {
                let res: any = response;
                if (res.status) {
                    if (res.status == 401) {
                        this.auth.logout()
                    }
                }
                if (res.success == 1) {
                    return res;
                }else if(res.success == 0){
                    return res;
                }

            });
    }

    deleteData(delete_url) {
        let url = `${this.apiUrl}` + `${delete_url}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.auth.getToken()
            })
        }
        return this.http.get(url, httpOptions)
            .map((response: Response) => {
                let res: any = response;
                if (res.status) {
                    if (res.status == 401) {
                        this.auth.logout()
                    }
                }
                if (res.success == 1) {
                    return res;
                }

            });
    }

    getDataById(url: string) {
        let get_url = `${this.apiUrl}` + `${url}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.auth.getToken()
            })
        }
        return this.http.get(get_url, httpOptions)
            .map((response: Response) => {
                let res: any = response;

                if (res.status) {
                    if (res.status == 401) {
                        return this.auth.logout()
                    }
                }
                if (res.success == 1) {
                    return res;
                }

            });
    }

    changeStatus(option: any, status_url: string) {
        let url = `${this.apiUrl}` + `${status_url}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.auth.getToken()
            })
        }
        return this.http.post(url, option, httpOptions)
            .map((response: Response) => {
                let res: any = response;
                if (res.status) {
                    if (res.status == 401) {
                        return this.auth.logout()
                    }
                }
                if (res.success == 1) {
                    return res;
                }

            });
    }

    setRedirectURL(url: string) {
        this.redirectUrl = url;
    }

    getRedirectURL() {
        return this.redirectUrl;
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + new Date().toDateString() + EXCEL_EXTENSION);
    }
}
