import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { auth } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthenticationService {
  user: Observable<firebase.User>;

  constructor(private afAuth: AngularFireAuth) {
    console.log(afAuth)
    this.user = afAuth.authState;
  }

  getUserId(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInAnonymously()
      this.afAuth.authState.subscribe(user => {
        if (user) {
          resolve({
            id: user.uid,
          });
        } else {
          reject(null);
        }
      },
      error => {
        console.error('Auth state error:', error);
      });
    });
  }
  // Add other authentication methods as needed (e.g., login, logout)
}
