import { Injectable } from '@angular/core';

@Injectable()
export class ThemeService {
    editButton = "btn btn-hero-info btn-demo btn-tn btn-xs";
    deleteButton = "btn btn-hero-danger btn-demo btn-tn btn-xs";
    saveButton = "btn btn-primary btn-raised";
    cancelButton = "btn btn-danger btn-raised  btn-delete";
    activeButton = "btn btn-hero-success btn-demo btn-tn btn-xs";
    inactiveButton = "btn btn-hero-danger btn-demo btn-tn btn-xs";
    viewButton = "btn btn-info btn-raised btn-tn btn-xs";
    excelButton = "btn btn-primary btn-raised mt-4 mr-2";

    constructor() {

    }
}
